<div class="app-container">
  @if (isLoggedIn) {
    <div class="app-sidebar">
      <a
        class="app-logo"
        href="#">
        <img
          class="app-logo-img"
          src="assets/images/logo.svg"
          alt="AgroSell logo" />
      </a>

      <nav class="app-nav">
        @for (route of config.routes$ | async; track route.title) {
          @if (isValidGroup(route)) {
            <div class="nav-title">
              {{ route.title | translate }}
            </div>

            @for (child of route.children; track child.title) {
              <div class="nav-submenu-container">
                @if (child.children) {
                  <a
                    (click)="onGroupClick(child)"
                    class="nav-link"
                    [routerLinkActive]="['active']">
                    <i
                      class="nav-icon"
                      [ngClass]="child.icon"></i>
                    {{ child.title | translate }}
                    <i
                      class="nav-icon-caret ph"
                      [ngClass]="{
                        'ph-caret-down': isGroupExpanded(child),
                        'ph-caret-right': !isGroupExpanded(child),
                        active: isGroupExpanded(child),
                      }"></i>
                  </a>
                  <div
                    class="nav-submenu-items"
                    *ngIf="isGroupExpanded(child)">
                    @for (subChild of child.children; track subChild.title) {
                      <a
                        class="nav-link nav-submenu-link"
                        [routerLink]="subChild.path"
                        [routerLinkActive]="['active']">
                        {{ subChild.title | translate }}
                      </a>
                    }
                  </div>
                } @else {
                  <a
                    class="nav-link"
                    (click)="onGroupClick(child)"
                    [ngClass]="{ active: isGroupExpanded(child) }"
                    [routerLink]="child.path"
                    [routerLinkActive]="['active']">
                    <i
                      class="nav-icon"
                      [ngClass]="child.icon"></i>
                    {{ child.title | translate }}
                  </a>
                }
              </div>
            }
          } @else {
            <a
              class="nav-link"
              (click)="unselectExpandedGroup()"
              [routerLink]="route.path"
              [routerLinkActive]="['active']">
              <i
                class="nav-icon"
                [ngClass]="route.icon"></i>
              {{ route.title | translate }}
            </a>
          }
        }
      </nav>

      <div class="app-user">
        <lib-menu-nav-profile></lib-menu-nav-profile>
      </div>
    </div>
  }

  <div class="app-main">
    <div class="app-header-nav">
      <lib-menu-nav [isLoggedIn]="isLoggedIn"></lib-menu-nav>
    </div>
    <router-outlet></router-outlet>
  </div>
</div>
