import { Injectable } from '@angular/core';
import { ApiListResponse, MessageDto, MessageRequestFilter, MessageRequestOrder, MessagesApiClientService, RequestList } from '@as/api';
import { interval, map, Observable, startWith, switchMap } from 'rxjs';
import { AuthService } from '../../../../common/auth';
import { OffersService } from '../../../offers/services/offers.service';

const MESSAGERE_FRESH_RATE_IN_MS = 1000 * 60;

@Injectable()
export class NotificationService {
  public unreadMessageCountStreamInterval$: Observable<number>;

  constructor(
    private readonly messagesProxyService: MessagesApiClientService,
    private readonly authService: AuthService,
  ) {
    this.unreadMessageCountStreamInterval$ = interval(MESSAGERE_FRESH_RATE_IN_MS).pipe(
      startWith(0),
      switchMap(() => this.getUnreadMessages()),
      map(data => this.mapToMessageCount(data)),
    );
  }

  public getUnreadMessages(): Observable<ApiListResponse<MessageDto>> {
    const request: RequestList<MessageRequestFilter, MessageRequestOrder> = {
      filter: [
        {
          field: 'recipientOrganizationId',
          value: this.authService.getOrganizationId(),
          condition: '=',
        },
        {
          field: 'threadType',
          value: OffersService.ThreadType,
          condition: '=',
        },
        {
          field: 'isRead',
          value: 'false',
          condition: '=',
        },
      ],
      page: 1,
      pageSize: 1,
    };
    return this.messagesProxyService.get(request);
  }

  private mapToMessageCount(response: ApiListResponse<MessageDto>): number {
    return response.pager.totalCount;
  }
}
