import { Component, Inject, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, withLatestFrom } from 'rxjs/operators';
import { AuthService } from '../../../../../common/auth';
import { IRouteProvider, LayoutConfigToken } from '../../common/models/layout-config.model';
import { RouteInfo } from '../../common/models/route-info.model';

@Component({
  selector: 'lib-full-layout',
  templateUrl: './full-layout.component.html',
  styleUrls: ['./full-layout.component.scss'],
})
export class FullLayoutComponent implements OnDestroy {
  private expandedGroup: RouteInfo | null = null;
  private subscription: Subscription;

  get isLoggedIn(): boolean {
    return this.auth.isLoggedIn();
  }

  constructor(
    @Inject(LayoutConfigToken) public readonly config: IRouteProvider,
    private readonly router: Router,
    private readonly auth: AuthService,
  ) {
    this.subscription = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        withLatestFrom(this.config.routes$),
      )
      .subscribe(([event, routes]) => {
        if (!routes) {
          return;
        }

        if (routes.length === 0) {
          return;
        }

        const expandedGroup = routes.filter(x => this.isValidGroup(x) && x.path && event.url.indexOf(x.path) === 0);
        if (expandedGroup && expandedGroup.length > 0) {
          this.expandedGroup = expandedGroup[0];
        }
      });
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  public onGroupClick(data: RouteInfo) {
    if (this.expandedGroup?.title === data.title) {
      this.expandedGroup = null;
      return;
    }

    this.expandedGroup = data;
  }

  public unselectExpandedGroup() {
    this.expandedGroup = null;
  }

  public isGroupExpanded(data: RouteInfo) {
    if (!this.expandedGroup) {
      return false;
    }

    return this.expandedGroup.title == data.title;
  }

  public isValidGroup(data: RouteInfo) {
    return data.children && data.children.length > 0;
  }
}
